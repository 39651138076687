import { TextField } from "@mui/material";
import { Comment } from "bloggers-backend/lib/pr/domain";
import { useField } from "formik";
import { AppContext } from "../../AppContext";
import React from "react";
import ButtonWithLoading from "./SubmitButton";
import { Comment as CommentComponent } from "./Comment";

interface CommentsProps {
  notifications: {
    entity: {
      name: string
      id: string
      type: string
    }
  }
  name: string
  loading: boolean
  onCommentsSubmit: () => void
}

export default function Comments(props: CommentsProps) {
  const [field, meta, helpers] = useField<Comment[]>(props.name);
  const [commentText, setCommentText] = React.useState('');

  const {user} = React.useContext(AppContext);
  
  return (
    <>
      <h2>Comments</h2>

      {
        field.value && field.value.map((value, idx) => <CommentComponent
            key={value.user.id + value.date}
            comment={{id: value.user.id + value.date, ...value}}
            canEdit={canEditComment(user, value)}
            onChange={(value) => {
              const before = field.value[idx];
              before.text = value;
              helpers.setValue(field.value);
            }}
            onDelete={() => {
              field.value.splice(idx, 1);
              helpers.setValue(field.value);
              props.onCommentsSubmit();
            }}
            notifications={props.notifications}
        />)
      }

      <TextField
        value={commentText}
        multiline
        label="New comment"
        id="new-comment"
        onChange={(event) => {
          setCommentText(event.target.value)
        }}
      />
      <ButtonWithLoading
        type={"button"}
        loading={props.loading}
        disabled={commentText === ''}
        onClick={() => {
          const newComments = field.value.concat([{
            text: commentText,
            date: new Date().toISOString(),
            user,
          }])

          helpers.setValue(newComments)
          setCommentText("");
          props.onCommentsSubmit();
        }}
      />
    </>
  )
}

interface User {
  role: string
  id: string
  name: string
}

function canEditComment(user: User, comment: {user: {id: string}}) {
  return user.role !== "admin" && comment.user.id !== user.id
}

