import { useCreate } from "./useCreate";
import { useValidateOnCreate } from "./useValidateOnCreate";

export function useCreateForm<Entity extends {}>(enitityName: string, navigateTo: string) {
  const {creating, create} = useCreate<Entity>(enitityName, navigateTo);
  const {validating, validate} = useValidateOnCreate<Entity>(enitityName);

  const isSubmitLoading = creating || validating;

  return {creating, create, isSubmitLoading, validating, validate};
}
