import { AppContext } from "../../../AppContext";
import { useContext, useState } from "react";
import { Result } from 'bloggers-backend/lib/domain/Result';

export function useValidateOnCreate<Entity extends {}>(enitityName: string) {
  const {client, notificationsService} = useContext(AppContext)
  const [validating, setValidating] = useState(false);

  async function validate(values: Entity) {
    setValidating(true);
      
    const result = await client<{}, Result<void>>({
      event: `${enitityName}.validateOnCreate`,
      data: values
    });

    setValidating(false);

    if (!result.success) {
      if (result.errors._) {
        notificationsService.notify({
          severity: 'error',
          message: JSON.stringify(result.errors._)
        })
      }
      return result.errors
    }
  }

  return {validating, validate};
}
