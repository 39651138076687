import { Box } from '@mui/system';
import { Button } from '@mui/material';
import { convertUtcToLocalTime } from '../Utils';
import * as React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Grid, { QueryOptions } from './Grid';
import { AppContext } from '../AppContext';
import { GetAllPublicRelationEvent, GetAllPublicRelationResult } from 'bloggers-backend/lib/pr/domain';

const columns = [
  {
    field: 'name',
    width: 150,
    filterable: true,
    sortable: true,
    renderCell: ({row}: any) => (<Link to={`/pr/${row?.id}`}>{row?.name}</Link>)
  },
  { field: 'link', width: 150, filterable: true, sortable: false },
  { field: 'manager.name', headerName: 'manager', width: 150, filterable: true, sortable: true, valueGetter: ({ row }: any) => row?.manager?.name },
  { field: 'status', width: 150, filterable: true, sortable: true },
  { field: 'email', width: 150, filterable: true, sortable: true },
  { 
    field: 'createTime', 
    headerName: 'create time', 
    width: 160, 
    filterable: true, 
    sortable: true,
    valueGetter: ({ row }: any) => convertUtcToLocalTime(row?.createTime)
  },
  {
    field: 'lastComment.text',
    headerName: 'last comment',
    width: 150,
    filterable: false,
    sortable: false,
    valueGetter: ({ row }: any) => row?.lastComment?.text
  },
  { 
    field: 'lastComment.date',
    headerName: 'last comment date',
    width: 160,
    filterable: true,
    sortable: true,
    valueGetter: ({ row }: any) => convertUtcToLocalTime(row?.lastComment?.date)
  },
  { field: 'language', width: 150, filterable: true, sortable: false },
  { field: 'mediaType', headerName: 'media type', width: 150, filterable: true, sortable: true },
  { field: 'topic', width: 150, filterable: true, sortable: false },
  { field: 'instagram', width: 150, filterable: true, sortable: false },
  { field: 'twitter', width: 150, filterable: true, sortable: false },
  { field: 'facebook', width: 150, filterable: true, sortable: false },
  { field: 'linkedIn', width: 150, filterable: true, sortable: false },
];

export default function PublicRelationsPage() {
  const {user, client, notificationsService} = React.useContext(AppContext);
  const navigate = useNavigate();

  const fetchData = async (options: QueryOptions) => {
    const result = await client<GetAllPublicRelationEvent, GetAllPublicRelationResult>({
      event: 'getAllPublicRelation',
      data: options as any
    })

    if (result.success) {
      return result.value
    } else {
      notificationsService.notify({
        severity: 'error',
        message: `Error: ${JSON.stringify(result.errors)}`
      });

      return {data: [], pageInfo: {}}
    }
  };

  return <Box sx={{display: 'flex', flexDirection: 'column', height: '100%' }}>
  <Box sx={{marginBottom: 1}}>
    <Button
      variant="contained"
      onClick={() => navigate('/pr/new')}
    >
      CREATE NEW PR
    </Button>
    {
        user.role === "admin"
        ? (<Button
          variant="contained"
          onClick={() => navigate('/pr_report')}
          sx={{marginLeft: 1}}
        >
          Report
        </Button>)
        : <></>
      }
  </Box>
  <Box sx={{flexGrow: 1}}>
    <Grid
      cacheName={'pr'}
      columns={columns}
      fetchData={fetchData}
      onRowClick={() => {}}
      showSearch={true}
    />
  </Box>
</Box>
}