interface Props {
  children?: React.ReactNode;
  loading: boolean;
  loadedData: null | {}
}

export const EditFormLoader = ({children, loading, loadedData}: Props) => {
  return (
    loading
    ? <></>
    : loadedData === null
    ? <p>Not found</p>
    : <>{children}</>
  )
};
