import { Formik } from 'formik';
import { TextField } from '../components/forms/TextField';
import { Autocomplete } from '../components/forms/Autocomplete';
import { countries } from 'bloggers-backend/lib/bloggers/dicts/countries';
import ButtonWithLoading from '../components/forms/SubmitButton';
import { themes } from 'bloggers-backend/lib/bloggers/dicts/themes';
import { statuses } from 'bloggers-backend/lib/bloggers/dicts/statuses';
import { matters } from 'bloggers-backend/lib/bloggers/dicts/matters';
import { hosts } from 'bloggers-backend/lib/bloggers/dicts/hosts';
import { UserField } from '../components/forms/UserField';
import Comments from '../components/forms/Comments';
import { Column } from '../components/forms/Column';
import { History } from '../components/forms/History';
import { NewEmailDialog } from '../components/NewEmailDialog';
import { Blogger } from 'bloggers-backend/lib/bloggers/domain';
import { useEditForm } from '../components/forms/hooks/useEditForm';
import { Row } from '../components/forms/Row';
import { DeleteFlow } from '../components/DeleteFlow';
import { EditFormLoader } from '../components/forms/EditFormLoader';


export default function EditBloggerPage() {
  const {loading, loadedData, update, isSubmitLoading, validate, deleteEntity} = useEditForm<Blogger>('Blogger', '/bloggers');
  
  return (
    <EditFormLoader loading={loading} loadedData={loadedData}>
      <Formik
        initialValues={loadedData}
        onSubmit={update}
        validate={validate}
      >
        {({values, submitForm}) => (
          <Row>
            <Column>
              <h2>Edit blogger: {values.name}</h2>

              <TextField required name="name" label="name"/>
              <Autocomplete name="country" label="country" options={countries}/>
              <Autocomplete name="channelTheme" label="channel theme" options={themes}/>
              <Autocomplete name="status" label="status" options={statuses}/>
              <Autocomplete name="matterOfContact" label="matter of contact" options={matters}/>
              <Autocomplete multiple={true} name="hosts" label="hosts" options={hosts}/>
              <TextField name="youtube" label="youtube"/>
              <TextField name="youtubeChannelId" label="youtubeChannelId"/>
              <TextField name="instagram" label="instagram"/>
              <TextField name="tikTok" label="tikTok"/>
              <TextField name="email" label="email"/>
              <TextField name="addEmail" label="additional email"/>
              <UserField name="manager" label="manager"/>
              
              <ButtonWithLoading loading={isSubmitLoading} onClick={submitForm}/>
              <DeleteFlow deleteWhat="Blogger" deleteAction={deleteEntity}/>
            </Column>
            <Column>
              <Comments
                    name="comments"
                    onCommentsSubmit={submitForm}
                    loading={isSubmitLoading}
                    notifications={{entity: {type: "blogger", id: values.id, name: values.name}}}
              />
              <History history={values.history}/>
            </Column>
            <Column>
              <h2>Emails</h2>
              <NewEmailDialog mail={{to: values.email}}/>
            </Column>
          </Row>
        )}
      </Formik>
    </EditFormLoader>
  );
}
