import { Formik } from 'formik';
import { Form } from '../components/forms/Form';
import { TextField } from '../components/forms/TextField';
import { Autocomplete } from '../components/forms/Autocomplete';
import { countries } from 'bloggers-backend/lib/bloggers/dicts/countries';
import ButtonWithLoading from '../components/forms/SubmitButton';
import { useCreateForm } from '../components/forms/hooks/useCreateForm';
import { useContext } from 'react';
import { AppContext } from '../AppContext';

export default function CreateBloggerPage() {
  const {isSubmitLoading, create, validate} = useCreateForm('Blogger', '/bloggers');
  const {user} = useContext(AppContext);

  return (<Formik
      initialValues={{
        name: '',
        youtube: '',
        country: null,
        instagram: '',
        tikTok: '',
        facebook: '',
        email: '',
        addEmail: '',
        youtubeChannelId: ''
      }}
      onSubmit={create}
      validate={validate}
    >
      <Form>
        <h2>Create a new blogger:</h2>
        <TextField required name="name" label="name"/>
        <TextField required={user.role === "creator"} name="youtube" label="youtube"/>
        <TextField name="youtubeChannelId" label="youtubeChannelId"/>
        <Autocomplete name="country" label="country" options={countries}/>
        <TextField name="instagram" label="instagram"/>
        <TextField name="tikTok" label="tikTok"/>
        <TextField name="facebook" label="facebook"/>
        <TextField name="email" label="email"/>
        <TextField name="addEmail" label="additional email"/>

        <ButtonWithLoading loading={isSubmitLoading}/>
      </Form>
    </Formik>
  );
}
