import { useParams } from "react-router-dom";
import { AppContext } from "../../../AppContext";
import { useContext, useEffect, useState } from "react";
import { Result } from 'bloggers-backend/lib/domain/Result';

export function useLoad<Entity extends {}>(enitityName: string) {
  const {id} = useParams();
  const [loading, setLoading] = useState(false);
  const {notificationsService, client} = useContext(AppContext);
  const [loadedData, setLoadedData] = useState<Entity>({} as Entity);

  useEffect(() => {
    if (!id || loading) {
      return;
    }


    loadData();
  }, [id]);

  async function loadData() {
    setLoading(true);
    const result = await client<{}, Result<Entity>>({
      event: `${enitityName}.get`,
      data: {id}
    });

    if (result.success) {
      setLoadedData(result.value);
    } else {
      notificationsService.notify({
        severity: 'error',
        message: JSON.stringify(result.errors)
      })
    }

    setLoading(false)
  }

  return {loading, loadedData};
}