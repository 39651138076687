import { Formik } from 'formik';
import { TextField } from '../../components/forms/TextField';
import { Autocomplete } from '../../components/forms/Autocomplete';
import { countries } from 'bloggers-backend/lib/studios/dicts/countries';
import ButtonWithLoading from '../../components/forms/SubmitButton';
import { statuses } from 'bloggers-backend/lib/studios/dicts/statuses';
import { hosts } from 'bloggers-backend/lib/studios/dicts/hosts';
import { UserField } from '../../components/forms/UserField';
import Comments from '../../components/forms/Comments';
import { Column } from '../../components/forms/Column';
import { History } from '../../components/forms/History';
import { NewEmailDialog } from '../../components/NewEmailDialog';
import { Studio } from 'bloggers-backend/lib/studios/domain';
import { useEditForm } from '../../components/forms/hooks/useEditForm';
import { Row } from '../../components/forms/Row';
import { DeleteFlow } from '../../components/DeleteFlow';
import { EditFormLoader } from '../../components/forms/EditFormLoader';


export default function EditStudioPage() {
  const {loading, loadedData, update, isSubmitLoading, validate, deleteEntity} = useEditForm<Studio>('studios', '/studios');
  
  return (
    <EditFormLoader loading={loading} loadedData={loadedData}>
      <Formik
        initialValues={loadedData}
        onSubmit={update}
        validate={validate}
      >
        {({values, submitForm}) => (
          <Row>
            <Column>
              <h2>Edit studio: {values.companyName}</h2>

              <TextField required name="companyName" label="company name"/>
              <Autocomplete required name="status" label="status" options={statuses}/>
              <Autocomplete required name="country" label="country" options={countries}/>
              <TextField required name="website" label="website"/>
              <TextField required name="email" label="email"/>

              <TextField name="additionalEmail" label="additional email"/>
              <TextField name="contactPersonName" label="contact person name"/>
              <Autocomplete multiple name="hosts" label="hosts" options={hosts}/>
              <TextField name="instagram" label="instagram"/>
              <TextField name="phone" label="phone"/>
              <TextField name="youtube" label="youtube"/>
              <UserField name="manager" label="manager"/>
              
              <ButtonWithLoading loading={isSubmitLoading} onClick={submitForm}/>
              <DeleteFlow deleteWhat="Studio" deleteAction={deleteEntity}/>
            </Column>
            <Column>
              <Comments
                    name="comments"
                    onCommentsSubmit={submitForm}
                    loading={isSubmitLoading}
                    notifications={{entity: {type: "studio", id: values.id, name: values.companyName}}}
              />
              <History history={values.history}/>
            </Column>
            <Column>
              <h2>Emails</h2>
              <NewEmailDialog mail={{to: values.email}}/>
            </Column>
          </Row>
        )}
      </Formik>
    </EditFormLoader>
  );
}
