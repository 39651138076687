import { AppContext } from "../../../AppContext";
import { useContext, useState } from "react";
import { Result } from 'bloggers-backend/lib/domain/Result';

export function useValidateOnUpdate<Entity extends {}>(enitityName: string) {
  const {client} = useContext(AppContext)
  const [validating, setValidating] = useState(false);

  async function validate(values: Entity) {
    setValidating(true);
      
    const result = await client<{}, Result<void>>({
      event: `${enitityName}.validateOnUpdate`,
      data: values
    });

    setValidating(false);

    if (!result.success) {
      return result.errors
    }
  }

  return {validating, validate};
}
