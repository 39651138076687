import { Card, CardContent, Typography } from "@mui/material"
import {stringify} from 'yaml';
import { formatISODate } from "../../Utils";

interface HistoryItem {
  changes: any
  isoTime: string
  user: {
    name: string
  }
}

interface HistoryProps {
  history?: HistoryItem[]
}

export function History(props: HistoryProps) {
  return (<>
    <h2>History</h2>
    {
      props.history?.map((value) => {
        return (
            <Card sx={{ minWidth: 275 }} key={value.isoTime}>
              <CardContent>
                <Typography color="text.secondary" sx={{font: 9}}>
                  <pre style={{fontSize: 14}}>
                    {stringify(value.changes)}
                  </pre>
                </Typography>
                <Typography color="text.secondary" sx={{font: 14}}>
                  {formatISODate(value.isoTime)}
                  <br></br>
                  {value.user.name}
                </Typography>
              </CardContent>
            </Card>
          )
      })
  }
  </>)
}