import * as React from "react";
import Box from '@mui/material/Box';

interface Props {
  children: React.ReactNode;
}

export const Row = ({children}: Props) => {
  return (
    <Box sx={{display: "flex", flexDirection: "row"}}>
      {children}
    </Box>
  );
};
