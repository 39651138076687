import { Formik } from 'formik';
import { Form } from '../../components/forms/Form';
import { TextField } from '../../components/forms/TextField';
import { Autocomplete } from '../../components/forms/Autocomplete';
import { countries } from 'bloggers-backend/lib/studios/dicts/countries';
import ButtonWithLoading from '../../components/forms/SubmitButton';
import { useCreateForm } from '../../components/forms/hooks/useCreateForm';
import { statuses } from 'bloggers-backend/lib/studios/dicts/statuses';
import { hosts } from 'bloggers-backend/lib/studios/dicts/hosts';

export default function CreateStudioPage() {
  const {isSubmitLoading, create, validate} = useCreateForm('studios', '/studios');

  return (<Formik
      initialValues={{
        companyName: '',
        status: '',
        country: '',
        website: '',
        email: '',

        additionalEmail: null,
        contactPersonName: null,
        hosts: [],
        instagram: null,
        phone: null,
        youtube: null
      }}
      onSubmit={create}
      validate={validate}
    >
      <Form>
        <h2>Create a new studio:</h2>
        <TextField required name="companyName" label="company name"/>
        <Autocomplete required name="status" label="status" options={statuses}/>
        <Autocomplete required name="country" label="country" options={countries}/>
        <TextField required name="website" label="website"/>
        <TextField required name="email" label="email"/>

        <TextField name="additionalEmail" label="additional email"/>
        <TextField name="contactPersonName" label="contact person name"/>
        <Autocomplete multiple name="hosts" label="hosts" options={hosts}/>
        <TextField name="instagram" label="instagram"/>
        <TextField name="phone" label="phone"/>
        <TextField name="youtube" label="youtube"/>

        <ButtonWithLoading loading={isSubmitLoading}/>
      </Form>
    </Formik>
  );
}
