import {Comment} from "../comments/domain";
import {HistoryRecord} from "../history/domain";

export enum Status {
  noContact = "no contact",
  noAnswer = "no answer",
  pending = "pending",
  notInterested = "not interested",
  noHost = "no host",
  client = "client"
}

export interface Studio {
  id: string
  companyName: string
  status: string
  country: string
  email: string
  manager: {
    id: string
    name: string
  }
  website: string
  comments: Comment[]
  history: HistoryRecord[]

  hosts: string[] | null
  phone: string | null
  additionalEmail: string | null
  contactPersonName: string | null
  youtube: string | null
  instagram: string | null
}

export type CreateStudioData = Omit<Studio, "id" | "comments" | "history" | "manager">
