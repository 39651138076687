import { Button } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { useNavigate, Link } from 'react-router-dom';

import Grid, { QueryOptions } from '../Grid';
import { AppContext } from '../../AppContext';

const columns = [
  {
    field: 'company name',
    width: 150,
    filterable: true,
    sortable: true,
    renderCell: ({row}: any) => (<Link to={`/studios/${row?.id}`}>{row?.companyName}</Link>)
  },
  { field: 'status', width: 150, filterable: true, sortable: true },
  { field: 'manager.name', headerName: 'manager', width: 150, filterable: true, sortable: true, valueGetter: ({ row }: any) => row?.manager?.name},
];


export default function StudiosPage() {
  const {client, notificationsService, user} = React.useContext(AppContext);
  const navigate = useNavigate();

  const fetchData = async (options: QueryOptions) => {
    const result = await client<any, any>({
      event: 'studios.getAll',
      data: options as any
    })

    if (result.success) {
      return result.value
    } else {
      notificationsService.notify({
        severity: 'error',
        message: `Error: ${JSON.stringify(result.errors)}`
      });

      return {data: [], pageInfo: {}}
    }
  };


  return <Box sx={{display: 'flex', flexDirection: 'column', height: '100%' }}>
    <Box sx={{marginBottom: 1}}>
      <Button
        variant="contained"
        onClick={() => navigate('/studios/new')}
      >
        CREATE NEW STUDIO
      </Button>
      {
        user.role === "admin"
        ? (<Button
          variant="contained"
          onClick={() => navigate('/studios/report')}
          sx={{marginLeft: 1}}
        >
          Report
        </Button>)
        : <></>
      }
    </Box>
    <Box sx={{flexGrow: 1}}>
      <Grid
        cacheName={'studios'}
        columns={columns}
        fetchData={fetchData}
        onRowClick={(id) => {}}
        showSearch={true}
      />
    </Box>
  </Box>
}
