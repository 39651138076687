import * as React from "react";
import Box from '@mui/material/Box';

interface Props {
  children: React.ReactNode;
}

export const Column = ({children}: Props) => {
  return (
    <Box sx={{display: "flex", flexDirection: "column", '& > :not(style)': { m: 1 }, width: '400px'}}>
      {children}
    </Box>
  );
};
