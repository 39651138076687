import * as React from 'react';

import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Report } from 'bloggers-backend/lib/report/domain';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { AppContext } from '../AppContext';
import { Result } from 'bloggers-backend/lib/domain/Result';

interface Props {
  statuses: string[]
  eventName: string
}

interface ReportQuery {
  event: string,
  data: {startDate: string, endDate: string}
}

export function ReportPage({statuses, eventName}: Props) {
  const {notificationsService, client} = React.useContext(AppContext);

  const [report, setReport] = React.useState<Report>([]);
  const [dateRange, setDateRange] = React.useState<DateRange<Dayjs>>([
    dayjs().subtract(1, 'month'), dayjs().add(1, 'day')
  ]);

  const onClick = async () => {
    if (!dateRange || !dateRange[0] || !dateRange[1]) {
      return;
    }
    const result = await client<ReportQuery, Result<Report>>({
      event: eventName,
      data: {
        startDate: dateRange[0].format('YYYY-MM-DD'),
        endDate: dateRange[1].format('YYYY-MM-DD'),
      }
    })
  
    if (result.success) {
      setReport(result.value);
    } else {
      notificationsService.notify({
        severity: 'error',
        message: `Error: ${JSON.stringify(result.errors)}`
      });
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker 
          value={dateRange}
          onChange={(newValue) => setDateRange(newValue)}
          format="DD/MM/YYYY"
          localeText={{ start: 'start date', end: 'end date' }} 
          
        />
      </LocalizationProvider>
      <Button onClick={onClick}>get report</Button>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold', width: '30%'}}>manager name</TableCell>
            <TableCell style={{ fontWeight: 'bold', width: '15%' }} align="right">comment count</TableCell>
            {statuses.map(status => <TableCell style={{ fontWeight: 'bold', width: '15%' }} align="right">{status}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
        {
          report.map(row => {
            return (
              <TableRow key={row.userName}>
                <TableCell>{row.userName}</TableCell>
                <TableCell align="right">{row.totalComments}</TableCell>
                {statuses.map(status => <TableCell align="right">{row.statuses[status] || 0}</TableCell>)}
              </TableRow>
            );
          })
        }
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}