import { AppContext } from "../../../AppContext";
import { useContext, useState } from "react";
import { Result } from 'bloggers-backend/lib/domain/Result';

interface Form<T> {
  resetForm(options: {values: T}): void
  setErrors(errors: {[key: string]: string[]}): void
}

export function useUpdate<Entity extends {}>(enitityName: string) {
  const {notificationsService, client} = useContext(AppContext)
  const [updating, setUpdating] = useState(false);

  async function update(values: Entity, form: Form<Entity>) {
    setUpdating(true);

    const result = await client<{}, Result<Entity>>({
      event: `${enitityName}.update`,
      data: values
    });

    if (result.success) {
      notificationsService.notify({
        severity: 'success',
        message: `${enitityName} updated`
      });

      form.resetForm({values: result.value});
    } else {
      if (result.reason === 'validation') {
        form.setErrors(result.errors)

        if (result.errors._) {
          notificationsService.notify({
            severity: 'error',
            message: result.errors._.join(', ')
          });
        }
      } else {
        notificationsService.notify({
          severity: 'error',
          message: result.reason
        })
      }
    }
    setUpdating(false);
  }

  return {updating, update};
}