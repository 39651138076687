import {Status} from "../domain";

export const statuses = [
  Status.client,
  Status.noAnswer,
  Status.noContact,
  Status.noHost,
  Status.notInterested,
  Status.pending,
];
