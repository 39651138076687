import { Navigate, Route, Routes } from "react-router-dom";
import CreateBloggerPage from "../pages/CreateBloggerPage";
import BloggersPage from "../pages/BloggersPage";
import BloggersImportPage from "../pages/BloggersImportPage";
import UserPage from "../pages/UserPage";
import EditBloggerPage from "../pages/EditBloggerPage";
import SearchTasksPage from "../pages/SearchTasksPage";
import SearchTaskPage from "../pages/SearchTaskPage";
import NotificationsPage from "../pages/NotificationsPage";
import UsersPage from "../pages/UsersPage";
import AppBar from "../AppBar";
import { Box } from "@mui/material";
import { ReportPage } from "../components/ReportPage";
import PublicRelationsPage from "../pages/PublicRelationsPage";
import PublicRelationPage from "../pages/PublicRelationPage";
import PublicRelationEditPage from "../pages/PublicRelationEditPage";
import { TimesheetPage } from "../pages/TimesheetPage";
import { TemplatesPage } from "../pages/TemplatesPage";
import { TemplatePage } from "../pages/TemplatePage";
import EmailAccounts from "../pages/EmailAccounts";
import CreateEmailAccountPage from "../pages/CreateEmailAccountPage";
import { EmailAccountPage } from "../pages/EmailAccountPage";
import PublicRelationsImportPage from "../pages/PublicRelationsImportPage";
import StudiosPage from "../pages/studios/StudiosPage";
import CreateStudioPage from "../pages/studios/CreateStudioPage";
import EditStudioPage from "../pages/studios/EditStudioPage";

export const AdminView = () => (
  <>
  <AppBar pages={
    [
      {
        label: 'Bloggers',
        route: '/bloggers'
      },
      {
        label: 'Search tasks',
        route: '/search-tasks'
      },
      {
        label: 'PR',
        route: '/pr'
      },
      {
        label: 'Studios',
        route: '/studios'
      },
      {
        label: 'Users',
        route: '/users'
      },
      {
        label: 'Notifications',
        route: '/notifications'
      },
      {
        label: 'Templates',
        route: '/templates'
      },
      {
        label: 'Email Accounts',
        route: '/email-accounts'
      },
      {
        label: 'Timesheet',
        route: '/timesheet'
      },
    ]
  }/>
  <Box sx={{ p: 3, flexGrow: 1}}>
    <Routes>
      <Route path="/users" element={<UsersPage/>}></Route>
      <Route path="/users/new" element={<UserPage />}></Route>
      <Route path="/users/:id" element={<UserPage />}></Route>
      
      <Route path="/" element={<Navigate to="/bloggers" />}></Route>
      
      <Route path="/bloggers" element={<BloggersPage/>}></Route>
      <Route path="/bloggers/import" element={<BloggersImportPage/>}></Route>
      <Route path="/bloggers/new" element={<CreateBloggerPage/>}></Route>
      <Route path="/bloggers/:id" element={<EditBloggerPage/>}></Route>
      
      <Route path="/search-tasks" element={<SearchTasksPage/>}></Route>
      <Route path="/search-tasks/new" element={<SearchTaskPage />}></Route>
      <Route path="/search-tasks/:id" element={<SearchTaskPage />}></Route>

      <Route path="/pr" element={<PublicRelationsPage/>}></Route>
      <Route path="/pr/new" element={<PublicRelationPage />}></Route>
      <Route path="/pr/:id" element={<PublicRelationEditPage />}></Route>
      <Route path="/pr/import" element={<PublicRelationsImportPage/>}></Route>
      
      {/*<Route path="/history-records" element={<HistoryRecordsPage/>}></Route> */}
      <Route path="/notifications" element={<NotificationsPage/>}></Route>

      <Route path="/bloggers_report" element={
        <ReportPage
          statuses={['no answer', 'pending', 'affiliate']}
          eventName="Blogger.getReport"
        />
      }/>
      <Route path="/pr_report" element={
        <ReportPage
          statuses={['no answer', 'pending', 'posted']}
          eventName="getPRReport"
        />
      }/>
      <Route path="/timesheet" element={<TimesheetPage/>}/>

      <Route path="/templates" element={<TemplatesPage/>}></Route>
      <Route path="/templates/new" element={<TemplatePage/>}></Route>
      <Route path="/templates/:id" element={<TemplatePage/>}></Route>

      <Route path="/email-accounts" element={<EmailAccounts/>}></Route>
      <Route path="/email-accounts/new" element={<CreateEmailAccountPage/>}></Route>
      <Route path="/email-accounts/:id" element={<EmailAccountPage/>}></Route>

      <Route path="/studios" element={<StudiosPage/>}></Route>
      <Route path="/studios/new" element={<CreateStudioPage/>}></Route>
      <Route path="/studios/report" element={
        <ReportPage
          statuses={['no answer', 'pending', 'client']}
          eventName="studios.getReport"
        />
      }></Route>
      <Route path="/studios/:id" element={<EditStudioPage/>}></Route>
    </Routes>
  </Box>
  </>
)