import { useField } from 'formik';
import MUITextField, {TextFieldProps as MUIProps} from '@mui/material/TextField';
import MUIAutocomplete from '@mui/material/Autocomplete';


interface AutocompleteProps extends Omit<MUIProps, "variant"> {
  label: string;
  name: string;
  type?: string;
  options: string[];
  multiple?: boolean;
}

export const Autocomplete = (props: AutocompleteProps) => {
  const [field, meta, helpers] = useField(props.name);

  return (
    <MUIAutocomplete
    id={field.name}
    multiple={props.multiple}
    onChange={(_, value) => helpers.setValue(value)}
    options={props.options}
    value={field.value}
    renderInput={(params) =>
      <MUITextField {...params}
        id={field.name}
        name={field.name}
        error={!!meta.error}
        helperText={meta.error}
        label={props.label}
      />}
  />
  );
};
