import { IconButton, Tooltip, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { convertUtcToLocalTime } from '../../Utils';
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import { Notification } from "bloggers-backend/lib/notifications/domain/Notification";
import { DeleteNotificationEvent, DeleteNotificationEventResult, GetAllNotificationEvent, GetAllNotificationEventResult } from "bloggers-backend/lib/notifications/domain/events";

interface NotificationsProps {
  commentId: string;
  cacheId: number;
  isDeleteDisabled: boolean;
  onChange: () => void
}

export function Notifications(props: NotificationsProps) {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const {client, notificationsService} = useContext(AppContext);

  useEffect(() => {
    (async () => {
      const result = await client<GetAllNotificationEvent, GetAllNotificationEventResult>({
        event: 'getAllNotifications',
        data: {
          filterModel: {
            items: [              
              {columnField: "commentId", operatorValue: "equals", value: props.commentId}
            ]
          }
        }
      });

      if (result.success) {
        setNotifications(result.value.data);
      } else {
        notificationsService.notify({
          severity: 'error',
          message: JSON.stringify(result.errors)
        });
      }
    })()
  }, [props.commentId, props.cacheId]);


  async function deleteNotification(id: string) {
    const result = await client<DeleteNotificationEvent, DeleteNotificationEventResult>({
      event: 'deleteNotification',
      data: {id}
    });
  
    if (result.success) {
      notificationsService.notify({
        severity: 'success',
        message: 'Notification deleted'
      });

      props.onChange()
    } else {
      notificationsService.notify({
        severity: 'error',
        message: JSON.stringify(result.errors)
      });
    }
  }

  return <>
    {notifications.map((notification) => (
      <div key={notification.id}>
        <Typography variant="body2">
          {convertUtcToLocalTime(notification.isoTime)}
          
          <Tooltip title="delete the notification">
            <IconButton
              size="small"
              aria-label="delete"
              disabled={props.isDeleteDisabled}
              onClick={
                () => {
                  deleteNotification(notification.id)
                }
              }
            >
              <DeleteIcon/>
            </IconButton>
          </Tooltip>

        </Typography>
      </div>
    ))}
  </>                  
}
