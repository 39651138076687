import { Button, CircularProgress } from "@mui/material"

interface ButtonWithLoadingProps {
  loading: boolean
  type?: "submit" | "button" | "reset" | undefined
  onClick?: () => void
  disabled?: boolean
}

export default function ButtonWithLoading({loading, type, onClick, disabled}: ButtonWithLoadingProps) {
  return loading ?
    <CircularProgress />
    : (
      <Button
        variant="contained"
        disabled={disabled}
        onClick={onClick}
        type={type ?? "submit"}>Submit
      </Button>
    )
}

