import { Card, CardContent, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { convertUtcToLocalTime } from "../../Utils";
import { useContext, useState } from "react";
import { PickDateDialog } from "../PickDateDialog";
import { AppContext } from "../../AppContext";
import { CreateNotificationEvent, CreateNotificationEventResult } from "bloggers-backend/lib/notifications/domain/events";
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import { Notifications } from "./Notifications";

interface CommentProps {
  comment: {
    id: string
    date: string
    text: string
    user: {
      name: string
    }
  }
  canEdit: boolean
  onChange: (value: string) => void
  onDelete: () => void
  notifications: {
    entity: {
      name: string
      id: string
      type: string
    }
  }
}

interface NotificationData {
  bloggerId: string;
  bloggerName: string;
  type: string;
  commentId: string;
  text: string;
}

export function Comment(props: CommentProps) {
  /**
   * cacheId is required for synchronizing updates on comment's notifications
   */
  const [cacheId, setCacheId] = useState<number>(0);
  const [pickDateDialogOpen, setPickDateDialogOpen] = useState(false);
  const [notification, setNotification] = useState<NotificationData>();

  const {notificationsService, client} = useContext(AppContext);

  async function createNotification(isoTime: string) {
    setPickDateDialogOpen(false);
  
    if (!notification) {
      throw new Error('Can not createNotification with no id');
    }
    const result = await client<CreateNotificationEvent, CreateNotificationEventResult>({
      event: 'createNotification',
      data: {
        ...notification,
        isoTime,
        complete: false
      }
    });

    if (result.success) {
      notificationsService.notify({
        severity: 'success',
        message: 'Notification created'
      });

      setCacheId(cacheId + 1);

    } else {
      notificationsService.notify({
        severity: 'error',
        message: JSON.stringify(result.errors)
      });
    }
  }

  return (
    <Card sx={{ minWidth: 275 }} key={props.comment.date}>
      <PickDateDialog
        isOpen={pickDateDialogOpen}
        userRejects={() => setPickDateDialogOpen(false)}
        userAccepts={createNotification}
      />
    <CardContent>
      <TextField
        value={props.comment.text}
        variant="standard" 
        multiline
        disabled={props.canEdit}
        sx={{width: '100%'}}
        onChange={(event) => props.onChange(event.target.value)}
      />
      <Typography color="text.secondary" sx={{font: 14}}>
        {convertUtcToLocalTime(props.comment.date)}
        <Tooltip title="delete the comment">
          <IconButton
            size="small"
            aria-label="delete"
            disabled={props.canEdit}
            onClick={() => props.onDelete()}
          >
            <DeleteIcon/>
          </IconButton>
        </Tooltip>
        <Tooltip title="add notification">
          <IconButton
            size="small"
            aria-label="delete"
            onClick= {
              () => {
                setPickDateDialogOpen(true)
                setNotification({
                  bloggerId: props.notifications.entity.id,
                  bloggerName: props.notifications.entity.name,
                  type: props.notifications.entity.type,
                  commentId: props.comment.id,
                  text: props.comment.text,
                })
              }
            }
          >
            <NotificationAddIcon/>
          </IconButton>
        </Tooltip>
        <br></br>
        {props.comment.user.name}
      </Typography>

      <TextField
        value={"Notifications"}
        variant="standard" 
        multiline
        disabled={true}
        sx={{width: '100%'}}
      />
      <Typography color="text.secondary" sx={{font: 14}}>
        <Notifications
          isDeleteDisabled={props.canEdit}
          commentId={props.comment.id}
          cacheId={cacheId}
          onChange={() => setCacheId(cacheId + 1)}
        />
      </Typography>
    </CardContent>
  </Card>
  )
}