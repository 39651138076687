import { useDelete } from "./useDelete";
import { useLoad } from "./useLoad";
import { useUpdate } from "./useUpdate";
import { useValidateOnUpdate } from "./useValidateOnUpdate";

export function useEditForm<Entity extends {}>(enitityName: string, navigateTo: string) {
  const {updating, update} = useUpdate<Entity>(enitityName);
  const {validating, validate} = useValidateOnUpdate<Entity>(enitityName);
  const {loading, loadedData} = useLoad<Entity>(enitityName);
  const {deleting, deleteEntity} = useDelete(enitityName, navigateTo);

  const isSubmitLoading = validating || updating || deleting;

  return {loading, loadedData, isSubmitLoading, update, validate, deleteEntity};
}