import * as React from "react";
import { useField } from 'formik';
import { UserLinkInput } from "../UserLinkInput";


interface UserFieldProps {
  label: string;
  name: string;
}

export const UserField = (props: UserFieldProps) => {
  const [field, meta, helpers] = useField(props.name);

  return (
      <UserLinkInput
        id={field.name}
        name={field.name}
        label={props.label}
        variant="outlined"
        required
        error={!!meta.error}
        helperText={meta.error}
        onChoose={(value) => helpers.setValue(value)}
        value={field.value}
    />
  );
};
