import { Box } from '@mui/system';
import * as React from 'react';

import Grid, { QueryOptions } from './Grid';
import { 
  GetAllNotificationEvent, 
  GetAllNotificationEventResult, 
  UpdateNotificationEvent, 
  UpdateNotificationEventResult 
} from 'bloggers-backend/lib/notifications/domain/events';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { Notification } from 'bloggers-backend/lib/notifications/domain/Notification';
import { useQueryClient } from '@tanstack/react-query';
import { AppContext } from '../AppContext';
import { convertUtcToLocalTime } from '../Utils';

function getEntityPath(notif: {type: string, bloggerId: string}) {
  switch (notif.type) {
    case 'blogger':
      return `/bloggers/${notif.bloggerId}`
    case 'pr':
      return `/pr/${notif.bloggerId}`
    case 'studio':
      return `/studios/${notif.bloggerId}`
    default:
      return ''
  }
}

export default function NotificationsPage() {
  const {notificationsService, client} = React.useContext(AppContext)
  const queryClient = useQueryClient()
  const cacheKey = 'notifications';

  const columns = [
    {
      field: 'bloggerName',
      headerName: 'blogger', 
      width: 150,
      filterable: true,
      sortable: true,
      renderCell: ({row}: any) => (<Link to={getEntityPath(row)}>{row?.bloggerName}</Link>)
    },
    {
      field: 'text',
      headerName: 'text',
      width: 150,
      filterable: true,
      sortable: true
    },
    { field: 'isoTime', headerName: 'time', width: 160, filterable: true, sortable: true, valueGetter: ({ row }: any) => convertUtcToLocalTime(row?.isoTime)},
    { field: 'user.name', headerName: 'user', width: 150, filterable: true, sortable: true, valueGetter: ({ row }: any) => row?.user?.name},
    { field: 'complete', type: 'boolean', headerName: 'done', width: 150, filterable: true, sortable: true},
    { field: 'type', headerName: 'type', width: 150, filterable: true, sortable: true},
    {
      field: 'actions',
      headerName: 'actions',
      width: 200,
      renderCell: ({row}: any) => (
        <Button
          onClick={() => updateNotification({...row, complete: !row.complete })}
          variant="contained">
            Mark as {row.complete ? 'Undone' : 'Done'} 
        </Button>
      )
    },
  ];

  const fetchData = async (options: QueryOptions) => {
    const result = await client<GetAllNotificationEvent, GetAllNotificationEventResult>({
      event: 'getAllNotifications',
      data: options as any
    })
  
    if (result.success) {
      return result.value
    } else {
      notificationsService.notify({
        severity: 'error',
        message: `Error: ${JSON.stringify(result.errors)}`
      });
  
      return {data: [], pageInfo: {}}
    }
  };

  const updateNotification = async (notification: Notification) => {
    const result = await client<UpdateNotificationEvent, UpdateNotificationEventResult>({
      event: 'updateNotification',
      data: notification
    })
  
    if (result.success) {
      notificationsService.notify({
        severity: 'success',
        message: `Notification updated`
      });
      queryClient.invalidateQueries({queryKey: [cacheKey]});
    } else {
      notificationsService.notify({
        severity: 'error',
        message: `Error: ${JSON.stringify(result.errors)}`
      });
  
      return {data: [], pageInfo: {}}
    }
  };

  return <Box sx={{display: 'flex', flexDirection: 'column', height: '100%' }}>
    <Box sx={{flexGrow: 1}}>
      <Grid
        columns={columns}
        cacheName={cacheKey}
        fetchData={fetchData}
        onRowClick={() => {}}
      />
    </Box>
  </Box>
}

