import { Formik } from 'formik';
import { TextField } from '../components/forms/TextField';
import { Autocomplete } from '../components/forms/Autocomplete';
import ButtonWithLoading from '../components/forms/SubmitButton';
import { statuses } from 'bloggers-backend/lib/pr/dicts/statuses';
import { languages } from 'bloggers-backend/lib/pr/dicts/languages';
import { mediaTypes } from 'bloggers-backend/lib/pr/dicts/mediaTypes';
import { topics } from 'bloggers-backend/lib/pr/dicts/topics';
import { UserField } from '../components/forms/UserField';
import Comments from '../components/forms/Comments';
import { Column } from '../components/forms/Column';
import { History } from '../components/forms/History';
import { useEditForm } from '../components/forms/hooks/useEditForm';
import { Row } from '../components/forms/Row';
import { DeleteFlow } from '../components/DeleteFlow';
import { PublicRelation } from 'bloggers-backend/lib/pr/domain';
import { EditFormLoader } from '../components/forms/EditFormLoader';


export default function PublicRelationEditPage() {
  const {loading, loadedData, update, isSubmitLoading, validate, deleteEntity} = useEditForm<PublicRelation>('PublicRelation', '/pr');
  
  return (
    <EditFormLoader loading={loading} loadedData={loadedData}>
      <Formik
        initialValues={loadedData}
        onSubmit={update}
        validate={validate}
      >
        {({values, submitForm}) => (
          <Row>
            <Column>
              <h2>Edit PR: {values.name}</h2>

              <TextField required name="name" label="name"/>
              <TextField required name="link" label="link"/>
              <Autocomplete name="status" label="status" options={statuses}/>
              <Autocomplete name="language" label="language" options={languages}/>
              <TextField name="email" label="email"/>
              <Autocomplete name="mediaType" label="mediaType" options={mediaTypes}/>
              <Autocomplete name="topic" label="topic" options={topics}/>
              <TextField name="instagram" label="instagram"/>
              <TextField name="facebook" label="facebook"/>
              <TextField name="twitter" label="twitter"/>
              <TextField name="linkedIn" label="linkedIn"/>
              <UserField name="manager" label="manager"/>

              <ButtonWithLoading loading={isSubmitLoading} onClick={submitForm}/>
              <DeleteFlow deleteWhat="PR" deleteAction={deleteEntity}/>
            </Column>
            <Column>
              <Comments
                    name="comments"
                    onCommentsSubmit={submitForm}
                    loading={isSubmitLoading}
                    notifications={{entity: {type: "pr", id: values.id, name: values.name}}}
              />
              <History history={values.history ?? undefined}/>
            </Column>
          </Row>
        )}
      </Formik>
    </EditFormLoader>
  );
}
