import { useContext, useState } from "react";
import { AppContext } from "../../../AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { Result } from "bloggers-backend/lib/domain/Result";

export function useDelete(entityName: string, navigateTo: string) {
  const [deleting, setDeleting] = useState(false);
  const {notificationsService, client} = useContext(AppContext);
  const navigate = useNavigate();
  const {id} = useParams();
 
  async function deleteEntity() {
    setDeleting(true);
  
    const result = await client<{}, Result<unknown>>({
      event: `${entityName}.delete`,
      data: {id}
    });
  
    if (result.success) {
      notificationsService.notify({
        severity: 'success',
        message: `${entityName} deleted`
      });
      navigate(navigateTo);
    } else {
      setDeleting(false);
      notificationsService.notify({
        severity: 'error',
        message: JSON.stringify(result.errors)
      });
    }
  }

  return {deleting, deleteEntity};
}
