import { useContext, useState } from "react";
import { AppContext } from "../../../AppContext";
import { Result } from "bloggers-backend/lib/domain/Result";
import { useNavigate } from "react-router-dom";

interface Form<T> {
  setErrors(errors: {[key: string]: string[]}): void
}

export function useCreate<Entity = {}>(entityName: string, navigateTo: string) {
  const {notificationsService, client} = useContext(AppContext)
  const [creating, setCreating] = useState(false);
  const navigate = useNavigate();

  async function create(values: Entity, form: Form<Entity>) {
    setCreating(true);
    const result = await client<{}, Result<string>>({
      event: `${entityName}.create`,
      data: values
    });

    if (result.success) {
      notificationsService.notify({
        severity: 'success',
        message: `${entityName} created`
      });
      navigate(`${navigateTo}/${result.value}`);
    } else {
      if (result.reason === 'validation') {
        form.setErrors(result.errors)

        if (result.errors._) {
          notificationsService.notify({
            severity: 'error',
            message: result.errors._.join(', ')
          });
        }
      } else {
        notificationsService.notify({
          severity: 'error',
          message: result.reason
        })
      }
    }
    setCreating(false);
  }

  return {creating, create};
}
